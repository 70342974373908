@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

    .ProseMirror ul,
    ol {
        list-style: revert;
        margin-left: 25px;
    }

    li {
        margin-left: 10px;
        list-style-type: disc;
    }
}

*::-webkit-scrollbar {
    width: 1rem;
}

*::-webkit-scrollbar-track {
    background: white;
}

*::-webkit-scrollbar-thumb {
    background-color: #d6d6d6;
    border-radius: 1.2rem;
    border: 4px solid #fff;
}

.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    width: 440px;
    height: 550px;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.dark-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9998;
}

div {
    max-width: 100%;
  }
    
